.blogContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 20px;
  text-align: center;
}

.banner {
  background-image: url('../../assets/images/pexels-olly-3777570.jpg');
  background-size: cover;
  background-position: center;
  padding: 80px 20px;
  border-radius: 12px;
  color: #00022B;
  margin-bottom: 40px;

  h1 {
    font-size: 3.5rem;
    font-weight: bold;
    margin-bottom: 15px;
    text-transform: uppercase;
  }

  p {
    font-size: 1.125rem;
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.6;
  }
}

.categories {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  margin-bottom: 30px;
}

.categoryButton {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #0077ff;
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #005bb5;
    transform: scale(1.05);
  }
}

.postGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)); // Slightly larger minimum size
  gap: 25px;
  margin-bottom: 40px;
}

.blogPost {
  position: relative;
  background-size: cover;
  background-position: center;
  height: 250px; // Increased height for a more impactful display
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.25);
  }

  .postLink {
    text-decoration: none;
    color: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    backdrop-filter: brightness(0.5);
    background-color: rgba(0, 0, 0, 0.4); // Dark overlay for better text contrast

    .postContent {
      text-align: center;
      padding: 20px;
      color: #fff;

      .postTitle {
        font-size: 1.25rem;
        font-weight: bold;
        margin-bottom: 5px;
      }

      .postExcerpt {
        font-size: 1rem;
        line-height: 1.4;
      }
    }
  }
}

.error {
  color: #ff4d4f;
  text-align: center;
  font-size: 1.2rem;
  margin-top: 20px;
}
