.breadcrumb-container {
  margin: 20px 0;
  font-family: 'Roboto', sans-serif;

  .MuiBreadcrumbs-root {
    font-size: 1rem;
    color: #333;
    display: flex;
    align-items: center;
  }

  .breadcrumb-link {
    text-decoration: none;
    display: flex;
    align-items: center;
    &:hover {
      text-decoration: underline;
    }
  }

  .breadcrumb-item {
    font-weight: 500;
    font-size: 1rem;
    display: flex;
    align-items: center;
  }

  .breadcrumb-icon {
    margin-right: 5px;
  }

  .MuiTypography-root {
    color: #333;
  }

  .MuiBreadcrumbs-separator {
    color: #777;
    display: flex;
    align-items: center;
  }

  .breadcrumb-item:last-child {
    color: #888;
    font-weight: bold;
  }

  .breadcrumb-item.active {
    color: purple;
    font-weight: 600;
  }
}
