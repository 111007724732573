.showcase {
  padding: 2rem;
  background-color: #f9f9f9;

  h1 {
    text-align: center;
    margin-bottom: 2rem;
  }

  section {
    margin-bottom: 3rem;

    h2 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  }

  .customerProjects {
    .projectList {
      display: flex;
      flex-wrap: wrap;
      gap: 1.5rem;

      .project {
        background-color: #fff;
        padding: 1.5rem;
        border: 1px solid #ddd;
        border-radius: 5px;
        flex: 1 1 30%;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        position: relative;
        cursor: pointer;

        h3 {
          margin-bottom: 0.5rem;
          font-family: 'Aptos', sans-serif;
          text-transform: uppercase;
          color: grey;
        }
        .status {
          display: flex;
          align-items: center;
          margin-top: 1rem;

          .statusCircle {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin-right: 0.5rem;

            &.wip {
              background-color: yellow;
              box-shadow: 0 0 5px rgba(255, 255, 0, 0.5);
            }

            &.maintenance {
              background-color: #19ff00;
              box-shadow: 0 0 5px rgb(25, 255, 0.5);
            }
            &.others {
              background-color: #00ffe9;
              box-shadow: 0 0 5px rgba(0, 255, 233, 0.5);
            }
          }

          span {
            color: grey;

            &.wip {
              color: #f0ad4e;
            }

            &.maintenance {
              color: #d9534f;
            }
          }
        }

        //.status {
        //  display: flex;
        //  align-items: center;
        //  margin-top: 1rem;
        //
        //  .statusCircle {
        //    width: 10px;
        //    height: 10px;
        //    border-radius: 50%;
        //    background-color: yellow;
        //    box-shadow: 0 0 5px rgba(255, 0, 0, 0.5);
        //    margin-right: 0.5rem;
        //  }
        //
        //  span {
        //    color: grey;
        //  }
        //}
      }
    }
  }

  .insightsBar {
    .insightsContainer {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .insightSection {
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 5px;
        padding: 1rem;
        margin: 0.5rem;
        flex: 1 1 30%;
        min-width: 300px;

        h3 {
          text-align: center;
          margin-bottom: 1rem;
        }
      }
    }
  }

  .highlightsCarousel {
    .embla {
      overflow: hidden;
      width: 100%;
    }

    .embla__container {
      display: flex;
      transition: transform 0.5s ease-in-out;
    }

    .embla__slide {
      flex: 0 0 100%; // Adjust based on how many items to show
      display: flex;
      align-items: center;
      justify-content: center;
      height: 200px; // Adjust based on your content
    }

    .embla__slide__img {
      width: 100%;
      height: auto;
      object-fit: cover; // Maintain aspect ratio
    }
  }
  }
