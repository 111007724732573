@import '../../styles/variables.scss';

.our-services-container {
	padding: 0 50px;
	.content-container {
		display: flex;
		flex-wrap: wrap;
		gap: 16px;
		justify-content: center;

		.MuiGrid-container {
			display: flex;
			flex-wrap: wrap;
			gap: 16px;
			justify-content: center;
		}

		.MuiGrid-item {
			flex: 1 1 100%;
			max-width: 100%;
			display: flex;
			justify-content: center;
			box-sizing: border-box;

			@media (min-width: 600px) {
				flex: 1 1 50%;
			}

			@media (min-width: 900px) {
				flex: 1 1 33.33%;
			}
		}

		.card-container {
			display: flex;
			flex-direction: column;
			max-width: 100%;
			flex: 1;
		}

		.MuiCard-root {
			border-radius: 12px;
			box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
			transition: box-shadow 0.3s ease, transform 0.3s ease;
			display: flex;
			flex-direction: column;
			height: 100%;
			overflow: hidden;
			flex: 1;

			&:hover {
				box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
				transform: translateY(-10px);
			}
		}

		.MuiCardContent {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding: 16px;
			flex: 1;
		}

		.card-content {
			display: flex;
			flex-direction: column;
			height: 100%;
		}

		.service-icon {
			font-size: 2rem;
			margin-bottom: 16px;
		}

		.tech-stack {
			display: flex;
			flex-wrap: wrap;
			gap: 8px;
			margin-bottom: 16px;
		}

		.pill {
			background-color: #f0f0f0;
			border-radius: 16px;
			padding: 4px 8px;
			font-size: 0.875rem;
		}

		a {
			display: flex;
			align-items: center;
			color: #007bff;
			text-decoration: none;
			font-weight: 500;

			.arrow-icon {
				margin-left: 8px;
			}
		}
	}

	h2 {
		text-align: center;
		margin-bottom: 32px;
		font-size: 26px;
		font-weight: $site-bold;
	}
}

//TODO: clear breakpoints with vars

// Mobile Styles
//	@media (max-width: $breakpoint-xs-up) {
//		.content-container {
//			flex-direction: column;
//			align-items: center;
//		}
//
//		.MuiCard-root {
//			width: calc(100% - 100px); // Full width with 50px padding on left and right
//			max-width: none;
//			margin-bottom: 1rem;
//		}
//	}
//
//	// Tablet/Small Screen Styles
//	@media (max-width: $breakpoint-sm-up) {
//		.MuiCard-root {
//			width: calc(100% - 100px); // Full width with padding on smaller screens
//		}
//	}
//
//	// Ensure gap between cards is consistent
//	@media (max-width: $breakpoint-md-down) {
//		.MuiCard-root {
//			margin-bottom: 1.5rem;
//		}
//	}
//}
