.detailContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 100px 20px;
}

.title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.content {
  font-size: 1.125rem;
  line-height: 1.8;
  color: #555;
}

.imageContainer {
  width: 100%;
  height: 300px; // Example height
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  margin-bottom: 20px;
}

.backLink {
  display: inline-block;
  margin-top: 30px;
  font-size: 1rem;
  color: #0077ff;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: #005bb5;
  }
}
