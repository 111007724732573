@import '../../styles/variables.scss';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.pageContainer {
  background: linear-gradient(rgba(240, 244, 248, 0.7), rgba(240, 244, 248, 0.7)), url('../../../public/vecteezy_portrait-of-a-business-team-at-a-meeting_12445426.jpg') no-repeat center /cover;
  width: 100%;
  height: 100vh;
}

.heroContainer {
  display: flex;
  flex-direction: column;
  padding-top: 140px;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  width: 90%;
  height: 70vh;
}

.heroTitle h1 {
  font-size: 70px;
  margin-bottom: 20px;
  display: inline-block;
  text-align: left;
  flex-grow: 1;
  line-height: 70px;
  color: #1B1212;
  font-weight: $site-bold;
  font-family: $font-body;
  flex-wrap: wrap;
  max-width: 400px;
}

.heroParagraph {
  display: inline-block;
  text-align: left;
  color: #1B1212;
  font-size: 18px;
}

.heroBtnContainer {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-top: 20px;
  padding-top: 30px;
  margin-left: 0;
  width: 100%;
  }
.btn-lets-chat,
.btn-portfolio {
  padding: 16px 45px;
  font-size: 16px;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  flex: 1;
}

.btn-lets-chat {
  background-color: #1B1212;
  color: white;
}

.btn-portfolio {
  background-color: #3779ff;
  color: white;
}

.btn-lets-chat:hover {
  background-color: #000;
  box-shadow: 1px 0 19px 4px #1B1212;
}


.btn-portfolio:hover {
  background-color: #042F86;
  box-shadow: 1px 0 19px 4px #042F86;
}

.scroll-indicator {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    animation: smoothBounce 2s infinite;
    z-index: 2;

    svg {
      fill: #1B1212;
    }
}

@media (min-width: 768px) {
  .heroContainer {
    height: 80%;
    width: 90%;
  }

  .heroTitle h1 {
    max-width: 500px;
  }

  .heroDynamicTitle {
    padding: 20px 0 20px 0;
    width: 50%;
  }

  .heroParagraph {
    width: 30%;
  }

  .heroBtnContainer {
    max-width: 500px;

  }
}

@keyframes smoothBounce {
  0% {
    transform: translateY(0);
    opacity: 2;
  }
  50% {
    transform: translateY(-15px);
    opacity: 2;
  }
  100% {
    transform: translateY(0);
    opacity: 2;
  }
}