.processSection {
  width: 100%;
  height: 100%;

  .processCard {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    text-align: center;
    border-bottom: 1px solid #ddd;

    .content {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px;
    }

    .image {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }

    .number {
      font-size: 2rem;
      color: #0073e6;
      margin-bottom: 10px;
    }

    h2 {
      font-size: 2rem;
      color: #0073e6;
      margin-bottom: 10px;
      line-height: 1.2;
    }

    p {
      font-size: 1.2rem;
      max-width: 90%;
      color: #555;
    }
  }

  .planning {
    border-left: solid 5px red;
  }

  .designDevelopment {
    border-left: solid 5px blue;
  }

  .testing {
    border-left: solid 5px green;
  }

  .ongoingSupport {
    border-left: solid 5px orange;
  }

  .cicd {
    border-left: solid 5px purple;
  }

  @media (min-width: 768px) {
    .processCard {
      flex-direction: row;
      text-align: left;
      justify-content: space-between;
      align-items: start;
      height: 100vh;
      padding: 40px;

      .content {
        align-items: flex-start;
        padding: 0;
      }

      .image {
        flex: 1;
        justify-content: flex-end;
        margin-top: 0;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .number {
        font-size: 3rem;
      }

      h2 {
        font-size: 3rem;
        margin-bottom: 20px;
        line-height: 70px;
      }

      p {
        font-size: 1.5rem;
        max-width: 800px;
      }
    }

    &:nth-child(2n) {
      flex-direction: row-reverse;
    }

    &:nth-child(2n + 1) {
      flex-direction: row;
    }
  }
}
