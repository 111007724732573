@import '../../styles/variables.scss';

@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap");


:root {
  --animationDistance: -100px;
  --animationDuration: 12s;
}

.wrapper {
  position: sticky;
  top: 0;
  left: 0; // Aligns to the left side
  right: 0; // Extends to the right
  padding: 10px 0;
  width: 100vw;
  white-space: nowrap;
  overflow: hidden;
  background: $color-accent;
  z-index: 1000;
  cursor: pointer;


  &:before {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

.inner {
  display: flex;
  white-space: nowrap;
  animation-duration: var(--animationDuration);
  animation-timing-function: linear;
}

.inner.moving {
  animation-name: moveticker;
}

.element {
  display: inline-block;
  padding: 0 20px;
  font-family: 'Quicksand', sans-serif;
  color: white;
  z-index: 3;
}

@keyframes moveticker {
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(var(--animationDistance));
  }
}

