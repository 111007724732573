@import '../../styles/variables.scss';

.home-page-container {
	.spiral-image {
		position: absolute;
		top: 40px;
		right: $spacing-2x;
		z-index: -1;
	}

	.subtitle {
		padding-right: 100px;
	}

	.book-a-call-button-container {
		.book-a-call-button {
			font-size: 18px;
			padding: 12px $spacing-3x 10px;
		}
	}

	.home-page-adv-container {

		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		padding: 20px;
		gap: 20px;
		align-items: stretch;
	}

	@media (max-width: 768px) {
		.home-page-adv-container {
			flex-direction: column;
		}
	}

	@media (min-width: $breakpoint-md-down) {
		.spiral-image {
			right: 50px;
		}

		.subtitle {
			padding-right: 150px;
		}
	}
}