.calculator-container {
  width: 100%;
  background-color: #f7f7f7;
  padding: 5px;
  border-radius: 12px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  animation: slideIn 1.5s ease-in-out;
  min-height: 500px;
  min-width: 285px;
  margin-top: 100px;

  h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1.6;
  }

  .input-group {
    display: flex;
    flex-direction: column;
    gap: 5px;

    label {
      font-size: 1rem;
      color: #555;
      display: block;
      margin-bottom: 5px;
    }

    input {
      width: 100%;
      padding: 10px;
      border-radius: 6px;
      border: 1px solid #ddd;
      font-size: 1rem;
    }

    input[type="number"] {
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
      width: 100%;
    }
  }

  h4 {
    font-size: 1.2rem;
    color: #333;
    margin-top: 15px;
    text-align: right;
  }
}

.calculator-wrapper {
  display: flex; // Add this wrapper for centering
  justify-content: center; // Center horizontally
  align-items: flex-start; // Align items at the start
  height: calc(100vh - 50px); // Adjust based on navbar height
}

@media (min-width: 600px) {
  .calculator-container {
    width: 100%;
    padding: 10px;
    min-height: 600px;

    h3 {
      font-size: 1.6rem;
    }

    .input-group {
      gap: 10px;
    }

    h4 {
      font-size: 1.4rem;
    }
  }
}

@media (min-width: 1024px) {
  .calculator-container {
    width: 45%;
    padding: 20px;
    min-height: 700px;

    h2 {
      line-height: 1.6;
    }

    h3 {
      font-size: 1.8rem;
    }

    .input-group {
      gap: 20px;
    }

    h4 {
      font-size: 1.5rem;
    }

    .calculate-btn {
      padding: 12px 25px;
    }
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
