.careers-page-container {
  .carousel-section {
    margin-top: 2rem;
    text-align: center;

    h2 {
      font-size: 1.8rem;
      margin-bottom: 1rem;
    }

    .carousel {
      display: flex;
      justify-content: center;
      gap: 1rem;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      padding-left: 1rem;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        height: 8px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }

    .custom-card {
      width: 400px;
      height: 300px;
      position: relative;
      overflow: hidden;
      border-radius: 8px;
      scroll-snap-align: center;
      flex-shrink: 0;
      display: flex;
      align-items: flex-end;

      .custom-card-media {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        filter: brightness(0.6);
      }

      .custom-card-content {
        position: relative;
        width: 100%;
        padding: 1rem;
        background: rgba(0, 0, 0, 0.6);
        color: white;
        font-size: 1rem;
        z-index: 1;
        text-align: center;
        transition: opacity 0.3s ease-in-out;
        opacity: 1;
      }
    }
  }
}
