@import './variables.scss';

.MuiButtonBase-root {
	&.MuiButton-root {
		font-family: $site-default-font-family;
		font-size: $site-default-font-size;
		font-weight: $site-regular;
		text-transform: none;
		line-height: 1;
		box-shadow: none;
		-webkit-box-shadow: none;
		outline: none;
		padding: 10px $spacing-2x 8px;

		&.MuiButton-contained {
			background: $button-color; // Use gradient for background
			border: none; // No border for contained buttons

			&:hover,
			&:hover:enabled,
			&:hover:active,
			&:hover:active:enabled {
				background: $button-contained-color-hover; // Gradient for hover state
			}

			&:focus,
			&:focus:enabled {
				background: $button-contained-color-focus; // Gradient for focus state
			}

			&:active,
			&:active:enabled,
			&:active:focus,
			&:active:focus:enabled {
				background: $button-color; // Gradient for active state
			}

			&:disabled,
			&:hover:disabled,
			&:active:disabled,
			&:focus:disabled,
			&:hover:active:disabled,
			&.disabled,
			&.disabled:hover,
			&.disabled:active,
			&.disabled:focus,
			&.Mui-disabled,
			&.Mui-disabled:hover,
			&.Mui-disabled:active,
			&.Mui-disabled:focus,
			&.Mui-disabled:active:focus {
				background: $button-background-color-disabled; // Background for disabled state
				color: $button-color-disabled; // Text color for disabled state
				cursor: not-allowed;
				pointer-events: inherit;
			}
		}

		&.MuiButton-outlined {
			background-color: transparent;
			border: 2px solid $button-outlined-border-color-hover; // Border color for outlined buttons
			color: $button-color;

			&:hover,
			&:hover:enabled,
			&:hover:active,
			&:hover:active:enabled {
				background-color: $button-outlined-color-hover; // Transparent background on hover
				border-color: $button-outlined-border-color-hover; // Border color on hover
				color: $button-text-color-hover; // Text color on hover
			}

			&:focus,
			&:focus:enabled {
				background-color: $button-outlined-color-focus; // Transparent background on focus
				border-color: $button-outlined-border-color-focus; // Border color on focus
				color: $button-text-color-focus; // Text color on focus
			}

			&:active,
			&:active:enabled,
			&:active:focus,
			&:active:focus:enabled {
				background-color: transparent; // Transparent background on active state
				border-color: $button-outlined-border-color-active; // Border color on active state
				color: $button-outlined-border-color-active; // Text color on active state
			}

			&:disabled,
			&:hover:disabled,
			&:active:disabled,
			&:focus:disabled,
			&:hover:active:disabled,
			&.disabled,
			&.disabled:hover,
			&.disabled:active,
			&.disabled:focus,
			&.Mui-disabled,
			&.Mui-disabled:hover,
			&.Mui-disabled:active,
			&.Mui-disabled:focus,
			&.Mui-disabled:active:focus {
				border-color: $button-color-disabled; // Border color for disabled state
				color: $button-color-disabled; // Text color for disabled state
				cursor: not-allowed;
				pointer-events: inherit;
			}
		}
	}

	&.MuiButton-startIcon {
		svg {
			font-size: 14px;
		}
	}

	&.MuiIconButton-root {
		&:hover {
			background-color: transparent;
		}
	}
}
@import './variables.scss';

.MuiButtonBase-root {
	&.MuiButton-root {
		font-family: $site-default-font-family;
		font-size: $site-default-font-size;
		font-weight: $site-regular;
		text-transform: none;
		line-height: 1;
		box-shadow: none;
		-webkit-box-shadow: none;
		outline: none;
		padding: 10px $spacing-2x 8px;

		&.MuiButton-contained {
			background: $button-color; // Use gradient for background
			border: none; // No border for contained buttons

			&:hover,
			&:hover:enabled,
			&:hover:active,
			&:hover:active:enabled {
				background: $button-contained-color-hover; // Gradient for hover state
			}

			&:focus,
			&:focus:enabled {
				background: $button-contained-color-focus; // Gradient for focus state
			}

			&:active,
			&:active:enabled,
			&:active:focus,
			&:active:focus:enabled {
				background: $button-color; // Gradient for active state
			}

			&:disabled,
			&:hover:disabled,
			&:active:disabled,
			&:focus:disabled,
			&:hover:active:disabled,
			&.disabled,
			&.disabled:hover,
			&.disabled:active,
			&.disabled:focus,
			&.Mui-disabled,
			&.Mui-disabled:hover,
			&.Mui-disabled:active,
			&.Mui-disabled:focus,
			&.Mui-disabled:active:focus {
				background: $button-background-color-disabled; // Background for disabled state
				color: $button-color-disabled; // Text color for disabled state
				cursor: not-allowed;
				pointer-events: inherit;
			}
		}

		&.MuiButton-outlined {
			background-color: transparent;
			border: 2px solid $button-outlined-border-color;
			color: $button-outlined-text-color;

			&:hover,
			&:hover:enabled,
			&:hover:active,
			&:hover:active:enabled {
				background-color: $button-outlined-color-hover; // Transparent background on hover
				border-color:$button-outlined-border-color-hover; // Border color on hover
				color: $button-outlined-text-color-hover;
			}

			&:focus,
			&:focus:enabled {
				background-color: $button-outlined-color-focus; // Transparent background on focus
				border-color: $button-outlined-border-color-focus; // Border color on focus
				color: $button-text-color-focus; // Text color on focus
			}

			&:active,
			&:active:enabled,
			&:active:focus,
			&:active:focus:enabled {
				background-color: transparent; // Transparent background on active state
				border-color: $button-outlined-border-color-active; // Border color on active state
				color: $button-outlined-border-color-active; // Text color on active state
			}

			&:disabled,
			&:hover:disabled,
			&:active:disabled,
			&:focus:disabled,
			&:hover:active:disabled,
			&.disabled,
			&.disabled:hover,
			&.disabled:active,
			&.disabled:focus,
			&.Mui-disabled,
			&.Mui-disabled:hover,
			&.Mui-disabled:active,
			&.Mui-disabled:focus,
			&.Mui-disabled:active:focus {
				border-color: $button-color-disabled; // Border color for disabled state
				color: $button-color-disabled; // Text color for disabled state
				cursor: not-allowed;
				pointer-events: inherit;
			}
		}
	}

	&.MuiButton-startIcon {
		svg {
			font-size: 14px;
		}
	}

	&.MuiIconButton-root {
		&:hover {
			background-color: transparent;
		}
	}
}
