@import '../../styles/variables.scss';

.footer-container {
	margin-top: 260px;
	//margin-bottom: $spacing-3x;
	background-color: #939eff;
	padding: 20px;
	border-radius: 30px 30px 0 0;

	.MuiTypography-root {
		color: #f0f4f8;
	}

	.MuiTypography-overline,
	.MuiTypography-caption,
	.MuiTypography-subtitle1 {
		color: #f0f4f8;
	}

	.newsletter-container {
		margin-bottom: $spacing-5x;
		text-align: center;

		.newsletter-subtitle {
			font-weight: $site-bold;
			margin-bottom: $spacing-2x;
		}

		.form-container {
			//margin-bottom: $spacing-2x;
			flex-direction: column;
			gap: $spacing-2x;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			max-width: 400px;
			margin: 0 auto;
		}
	}

	.grid-container {
		display: flex;
		justify-content: space-between;

		.item {
			a {
				color:#f0f4f8;
				//color: inherit;
			}

			.footer-section-title {
				color: #f0f4f8;
				//color: $site-headline-color;
				font-weight: $site-bold;
				margin-bottom: $spacing-1x;
			}
		}
	}

	.social-media-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: $spacing-2x;
		margin-bottom: $spacing-3x;



		.social-media {
			display: flex;
			gap: $spacing-2x;

			.fab {
				font-size: 24px;
				color: #f0f4f8;
				//color: $site-text-color;
			}
		}
	}





	@media (min-width: $breakpoint-sm-down) {
		.grid-container {
			justify-content: center;
			gap: 200px;
		}

		.social-media-container {
			justify-content: center;
			gap: 40px;
		}

		.newsletter-container {
			.form-container {
				flex-direction: row;
				justify-content: center;
				gap: $spacing-2x;
			}
		}
	}

	@media (max-width: $breakpoint-xs-up) {
		.grid-container {
			gap: 50px;

			.item {
				margin-bottom: $spacing-2x;
			}
		}
	}
}

.partner-badge {
	margin-top: 30px;
	text-align: center;

}
