@import '../../styles/variables.scss';

.header-container {
    position: fixed;
	left: 0;
	right: 0;
    display: flex;
    align-items: center;
    height: 60px;
    color: $site-text-color;
    line-height: 1;
    z-index: 999;
    box-shadow: none;

    .grid-container {
        align-items: center;
    }
}

.header--scrolled {
    background: rgba(240, 244, 248, 0.1);
    backdrop-filter: blur(10px);
}