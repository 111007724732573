@import '../../styles/variables.scss';

.about-page-container {
	.spiral-image {
		position: absolute;
		top: 40px;
		right: $spacing-2x;
		z-index: -1;
	}

	.subtitle {
		padding-right: 100px;
	}




	@media (min-width: $breakpoint-md-down) {
		.spiral-image {
			right: 50px;
		}

		.subtitle {
			padding-right: 150px;
		}
	}
}