.carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  font-size: 5rem;
  color: #010E54;
}

.slick-slider {
  overflow: hidden;
}

.slick-track {
  display: flex;
  align-items: center;
}

.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-prev,
.slick-next {
  display: none !important;
}

.slick-list {
  pointer-events: none;
}