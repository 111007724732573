.glossary-container {
    margin: 0 auto;
    padding: 20px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    h2 {
      color: rgb(240, 100, 34);
      text-align: center;
    }
  
    .search-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      width: 100%;
  
      input[type="text"] {
        padding: 10px;
        border-radius: 8px;
        border: 2px solid rgb(245, 163, 163);
        width: 50%;  // Half of screen width
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
        &:focus {
          border-color: rgb(240, 100, 34);
          box-shadow: 0 4px 6px rgba(240, 100, 34, 0.5);
          outline: none;
        }
      }
  
      .search-button {
        background-color: rgb(240, 100, 34);
        border: none;
        color: white;
        padding: 10px 15px;
        margin-left: 10px;
        border-radius: 8px;
        cursor: pointer;
      }
  
      .toggle-button {
        padding: 10px 15px;
        background-color: rgb(240, 100, 34);
        border: none;
        color: white;
        border-radius: 8px;
        cursor: pointer;
        margin-right: 10px;
      }
    }
  
    table {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      border-radius: 12px;
      overflow: hidden;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
      th, td {
        padding: 12px 15px;
        border: none;
        text-align: left;
      }
  
      th {
        background-color: rgb(240, 100, 34);
        color: white;
        border-top-left-radius: 12px; 
        border-top-right-radius: 12px;
      }
  
      tr:nth-child(even) {
        background-color: rgb(245, 163, 163);
      }
  
      tr:nth-child(odd) {
        background-color: white;
      }
  
      tr {
        border-bottom: 1px solid #ddd;
      }
  
      .glow {
        animation: glow 1s ease-in-out infinite alternate;
      }
  
      @keyframes glow {
        from {
          text-shadow: 0 0 10px rgb(240, 100, 34), 0 0 20px rgb(240, 100, 34), 0 0 30px #ff8c00, 0 0 40px #ff8c00, 0 0 50px #ff8c00, 0 0 60px #ff8c00, 0 0 70px #ff8c00;
        }
        
        to {
          text-shadow: 0 0 20px rgb(240, 100, 34), 0 0 30px #ff4500, 0 0 40px #ff4500, 0 0 50px #ff4500, 0 0 60px #ff4500, 0 0 70px #ff4500, 0 0 80px #ff4500;
        }
      }
    }
  }