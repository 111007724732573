@import 'spacing.scss';
// TYPOGRAPHY
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
// Fonts
$font-heading: 'IBM Plex Sans', sans-serif;
$font-body: 'Inter', sans-serif;
$font-monospace: 'Fira Code', monospace;

// Font weights
$site-regular: 400;
$site-bold: 700;
$font-semibold-weight: 600;
$material-icons: 'Material Icons';

// Font sizes
$site-default-font-size: 16px;
$site-h2-font-size: 26px;
$site-default-mobile-font-size: 14px;

// FONT FAMILY VARIABLES
$site-default-font-family: $font-body;
$site-alt-font-family: $font-heading;
$site-special-text: $font-monospace;


// common colors
$site-background-color: #f0f4f8;
$site-headline-color: #0a192f; // Dark Blue for headlines
// COLOR SCHEME
// Primary
$color-primary: #0a192f; // Dark Blue for headlines
$color-secondary: #00ff99; // Neon Green

// Accent
$color-accent: #0077ff; // Electric Blue
$color-accent-light: #00bcd4; // Soft Cyan

// Neutrals
$site-text-color: #2e2e2e; // Charcoal Gray for body
$color-neutral-light: #b0bec5; // Silver Gray
$color-background: #f0f4f8; // Soft White



$error-color: #7b010b;
//
//$link-color: #f06422;
//$link-color-hover: #f9992c;
//$link-color-focus: #f9992c;
//$link-color-active: #dc531e;
//
//// buttons
//$button-color: rgb(240, 100, 34);
//$button-contained-color-hover: #f9992c;
//$button-contained-color-focus: #f9992c;
//$button-outlined-color-hover: transparent;
//$button-outlined-color-focus: transparent;
//$button-outlined-border-color-hover: #f9992c;
//$button-outlined-border-color-focus: #f9992c;
//$button-text-color-hover: #f9992c;
//$button-text-color-focus: #f9992c;
//$button-outlined-border-color-active: #dc531e;
//$button-background-color-inactive: #f5f5f5;
//$button-background-color-disabled: #bdbdbd;
//$button-color-disabled: #757575;
// Error color
//
//
// NEW buttons
$button-color: $color-accent;
//$button-contained-color-hover: #f9992c;
//$button-contained-color-focus: #f9992c;
//$button-outlined-color-hover: transparent;
//$button-outlined-color-focus: transparent;
//$button-outlined-border-color-hover: #f9992c;
//$button-outlined-border-color-focus: #f9992c;
//$button-text-color-hover: #f9992c;
//$button-text-color-focus: #f9992c;
//$button-outlined-border-color-active: #dc531e;
//$button-background-color-inactive: #f5f5f5;
//$button-background-color-disabled: #bdbdbd;
//$button-color-disabled: #757575;
// Error color
$error-color: #ff4d4f;

// Link colors
$link-color: #0077cc; // Vibrant Blue for primary links
$link-color-hover: #005ea3; // Slightly darker blue for hover state
$link-color-focus: #005ea3;
$link-color-active: #004080; // Darker blue for active state
// Buttons
$button-color: linear-gradient(45deg, #f2642c, #dc531e); // Gradient for buttons
$button-contained-color-hover: linear-gradient(45deg, #dc531e, #f2642c); // Gradient for hover state
$button-contained-color-focus: linear-gradient(45deg, #ff4e50, #f9d423); // Alternative gradient for focus state
$button-outlined-color-hover: transparent; // Outlined button background on hover
$button-outlined-color-focus: transparent; // Outlined button background on focus
//$button-outlined-border-color: #f2642c;
//$button-outlined-border-color-hover: #dc531e; // Orange border for hover on outlined buttons
//$button-outlined-border-color-focus: #f2642c; // Orange border for focus on outlined buttons
//$button-outlined-text-color: #f2642c;
//$button-outlined-text-color-hover: #dc531e;
$button-text-color-hover: #ffffff; // White text color on hover
$button-text-color-focus: #ffffff; // White text color on focus
//$button-outlined-border-color-active: #dc531e; // Darker orange border on active state
$button-background-color-inactive: #f5f5f5; // Light gray for inactive button background
$button-background-color-disabled: #bdbdbd; // Light gray for disabled background
$button-color-disabled: #757575; // Disabled text color

//BUTTONS
//Primary CTA - to take a significant action towards purchasing or signing up for services
//main CTA normal state
$btn-primary: #007BFF;
//main  CTA hover state
$btn-primary-hover: #0056b3;
//main CTA active state
$btn-primary-active: #004080;


//outlined CTA normal state
$button-outlined-border-color: #004080;
$button-outlined-text-color: #004080;
//outlined CTA hover state
$button-outlined-border-color-hover: #0064c2;
$button-outlined-text-color-hover: #0064c2;
//outlined CTA focus
$button-outlined-border-color-focus: #0064c2;
//outlined CTA active state
$button-outlined-border-color-active: #001736; // Darker orange border on active state




$btn-primary-variant-2: #1B1212;
$btn-primary-variant-2-hover: #3A2C2C;


$footer-bg: #acb6ff;
// responsive breakpoints
$breakpoint-xs-down: 320px;
$breakpoint-xs-up: 599px;
$breakpoint-sm-down: 600px;
$breakpoint-sm-up: 899px;
$breakpoint-md-down: 900px;
$breakpoint-lg-top: 1200px;
