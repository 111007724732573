@import '../../../../styles/variables.scss';

.header-hamburger-container {
	display: flex;
	justify-content: flex-end;
}

.hamburger-drawer-container {
	padding: $spacing-2x $spacing-4x;

	a {
		font-family: $site-default-font-family;
		font-size: $site-default-font-size;
		color: $site-text-color;
		font-weight: $site-regular;
		text-decoration: none;
	}

	li {
		margin-bottom: $spacing-3x;
	}
}