@import '../../styles/variables.scss';

.resources-container {
  padding-top: 80px;
  padding-left: 80px;
}

.video-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px;
}

.video-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 15px;
  position: relative;
  transition: transform 0.2s;

  &:hover {
    transform: translateY(-5px);
  }

  &.placeholder {
    .video-thumbnail {
      background-color: #ddd;
      height: 180px;
      border-radius: 8px;
    }

    h2 {
      background-color: #eee;
      color: transparent;
      margin: 10px 0;
      height: 20px;
      border-radius: 4px;
    }

    p {
      background-color: #eee;
      color: transparent;
      height: 40px;
      border-radius: 4px;
      margin: 10px 0;
    }

    .watch-button {
      background-color: transparent;
      border: none;
      color: orange;
      cursor: pointer;
      font-weight: bold;
      position: absolute;
      bottom: 0px;
      right: 10px;
      padding: 5px 10px;
      display: flex;
      align-items: center;

      svg {
        margin-right: 5px;
        width: 16px;
        height: 16px;
        fill: orange;
      }
    }
  }
}
