.demos {
  text-align: center;
  padding: 20px;
}

h1 {
  padding-bottom: 40px;
  padding-top: 60px;}

.demosGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.demoCard {
  position: relative;
  border: 1px solid #7c8bfa;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(51, 73, 255, 0.46);
  overflow: hidden;
  background-color: #e4e9fc;
}

.demoTitle {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 40px;
  padding-left: 10px;
  text-align: left;
}

.demoIframe {
  width: 100%;
  height: 400px;
  border: none;
  border-radius: 20px;
}

.externalLinkIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: #007BFF;
  cursor: pointer;
  transition: color 0.3s ease;
}

.externalLinkIcon:hover {
  color: #0056b3;
}
